import axios from '@axios';

export default {
  namespaced: true,
  state: {
    newProducts: []
  },
  getters: {
    GET_ALL_PRODUCTS(state) {
        return state.products;
    },
    GET_PRODUCT(state) {
        return state.product;
    },
    GET_ALL_CATEGORIES(state) {
        return state.categories;
    },
  },
  mutations: {
    SET_NEW_PRODUCTS(state, products) {
        state.newProducts = products
    },
  },
  actions: {
    async getNewProducts ({ commit }) {
            const res = await axios.get("storage-products")
            commit('SET_NEW_PRODUCTS', res.data)
    },
    async addProductInShop ({ commit }, id) {
            await axios.get(`storage-products/save/${id}`)
    },
    async removeNewProduct ({ commit }, itemId) {
        await axios.delete(`storage-products/${itemId}`)
    }
  },
}
