import axios from '@axios'

export default {
  namespaced: true,
  state: {
    products: [],
    product: [],
    categories: [],
    orders: [],
    sizeDescriptions: [],
    options: [],
    selectedOrder: {},
  },
  getters: {
    GET_ALL_PRODUCTS(state) {
      return state.products
    },
    GET_PRODUCT(state) {
      return state.product
    },
    GET_ALL_CATEGORIES(state) {
      return state.categories
    },
    GET_ALL_ORDERS(state) {
      return state.orders
    },
    GET_SIZE_DESCRIPTIONS(state) {
      return state.sizeDescriptions
    },
    GET_ALL_OPTIONS(state) {
      return state.options
    },
    GET_SELECTED_ORDER(state) {
      return state.selectedOrder
    },
  },
  mutations: {
    // SET_ALL_NEWS(state, payload) {
    //   state.allNews = payload;
    // },
    ADD_ITEM(state, item) {
      state.products.unshift(item)
    },
    SET_PRODUCTS(state, products) {
      state.products = products
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_PRODUCT(state, product) {
      state.product = product
    },
    UPDATE_PRODUCT(state, product) {
      const productIndex = state.products.findIndex(p => p.id === product.id)
      Object.assign(state.products[productIndex], product)
    },
    REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.products.findIndex(p => p.id === itemId)
      state.products.splice(ItemIndex, 1)
    },
    SET_ORDERS(state, orders) {
      state.orders = orders
    },
    SET_SIZE_DESCRIPTIONS(state, sizeDescriptions) {
      state.sizeDescriptions = sizeDescriptions
    },
    SET_ALL_OPTIONS(state, options) {
      state.options = options
    },
    SET_SELECTED_ORDER(state, order) {
      state.selectedOrder = order
    },
  },
  actions: {
    async searchPhotos({ commit }, payload) {
      try {
      } catch (error) {
        console.log('search photos Error', error)
      }
    },
    async addItem({ commit }, item) {
      const res = await axios.post('products', item)
      commit('ADD_ITEM', Object.assign(item, { id: res.data.new_item.id }))
    },
    async fetchDataListItems({ commit }, query) {
      const res = await axios.get('product', { params: query })
      commit('SET_PRODUCTS', res.data.rows)
    },
    async fetchCategories({ commit }) {
      const res = await axios.get('category')
      commit('SET_CATEGORIES', res.data)
    },
    async fetchDataById({ commit }, id) {
      const res = await axios
        .get(`product/${id}`)
        .then(r => r?.data?.product || {})
        .catch(e => {
          console.log(e)
        })
      commit('SET_PRODUCT', res)
      return res
    },
    async updateItem({ commit }, item) {
      const productId = item.product.id
      delete item.product.id
      return axios.patch(`product/${productId}`, item)
      // commit('UPDATE_PRODUCT', res.data.new_item)
    },
    async removeItem({ commit }, itemId) {
      await axios.delete(`product/${itemId}`)
      commit('REMOVE_ITEM', itemId)
    },
    async fetchOrdersList({ commit }, query) {
      const res = await axios.get('order', { params: query })
      commit('SET_ORDERS', res.data.rows)
    },
    async fetchOrderById({ commit }, orderId) {
      const res = await axios.get(`order/${orderId}`)
      commit('SET_SELECTED_ORDER', res.data)
    },
    async updateOrder({ commit }, order) {
      const res = await axios.put(`order/${order.id}`, order)
      commit('SET_SELECTED_ORDER', res.data.payload)
      return res
    },
    async removeOrder({ commit }, orderId) {
      await axios.delete(`order/${orderId}`)
    },
    async fetchSizeDescriptions({ commit }, query) {
      const res = await axios.get('size-description', { params: query })
      commit('SET_SIZE_DESCRIPTIONS', res.data.rows)
    },
    async fetchOptions({ commit }, query) {
      const res = await axios.get('product-option', { params: query })
      commit('SET_ALL_OPTIONS', res.data.rows)
    },
    async syncProduct({ commit }, options) {
      return axios.post(`product/${options.id}/storage-sync?auto-fix=${options.autoFix || false}`)
        .then(r => r.data)
    },
    updateProduct({commit}, product){
      commit('UPDATE_PRODUCT', product)
    }
  },
}
