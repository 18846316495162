export default {
  namespaced: true,
  state: {
    loginModalIsOpen: false,
  },
  mutations: {
    setloginModalIsOpen(state, value) {
      state.loginModalIsOpen = value
    },
  },
  actions: {
    setloginModalIsOpen({ commit }, value) {
      commit('setloginModalIsOpen', value)
    },
  },
  getters: {
    setloginModalIsOpen(state) {
      return state.loginModalIsOpen
    },
  },
}
