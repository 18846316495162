import axios from '@axios';

export default {
  namespaced: true,
  state: {
    allNews: [],
  },
  getters: {
    GET_ALL_NEWS(state) {
      console.log(state.allNews)
      return state.allNews;
    },
  },
  mutations: {
    SET_ALL_NEWS(state, payload) {
      state.allNews = payload;
    },
  },
  actions: {
    async getAllNews({commit}, payload) {
      try {
        const res = await axios.get("news",
        {
          params: {
            sorting: 'createdAt DESC',
            skipCount: payload.skipCount,
            hashTags: payload.hashTags,
            maxResultCount: payload.maxResultCount
          }
        })
        .catch(e => {
          console.log(e)
        });
        console.log('RES',res.data.rows)
        commit('SET_ALL_NEWS', JSON.parse(JSON.stringify(res.data.rows))); 
  
      } catch (error) {
        console.log('get all news ERROR', error);
      }
    },
  },
}
