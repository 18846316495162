import router from '@/router'

export default {
  // Endpoints
  loginEndpoint: '/authentication/log-in',
  registerEndpoint: '/authentication/register',
  refreshEndpoint: '/authentication/refresh',
  logoutEndpoint: '/authentication/log-out',
  getUserConnectionTokenEndpoint: '/authentication/get-user-connection-token',
  meEndpoint: '/authentication',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'access_token',
  storageRefreshTokenKeyName: 'refresh_token',

  logOutCallback: () => {
    router.push({ name: 'auth-login' })
  },
}
